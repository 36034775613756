.gridjs-container {
  @apply overflow-x-auto touch-pan-x pb-4 md:pb-0;
}
.ui-grid-table,
.ui-grid-table-sm {
  @apply w-full;
}

/* GridJS alternating bgcolor rows */
/* Used for Additional Benefits */
.ui-grid-table th.gridjs-th {
  @apply text-xs py-2 px-3 text-left font-normal;
}
.ui-grid-table .gridjs-th-content {
}

.ui-grid-table td.gridjs-td {
  @apply text-sm py-2 px-3 text-left font-semibold;
}
.ui-grid-table .gridjs-tbody tr:nth-child(odd) {
  @apply bg-ui-gray-lightest;
}

/* GridJS row's bottom border */
/* Recent Policy Messages */
.ui-grid-table-borders th.gridjs-th {
  @apply text-xs py-1 px-3 text-left font-normal;
}
.ui-grid-table-borders .gridjs-th-content {
}

.ui-grid-table-borders td.gridjs-td {
  @apply text-sm py-1 px-3 text-left font-semibold;
}
.ui-grid-table-borders .gridjs-tbody tr {
  @apply border-t border-ui-gray-medium-light;
}
.ui-grid-table-borders .gridjs-tbody tr:first-child {
  @apply border-t-transparent;
}

/* GridJS for small tables in payment cards */
.ui-grid-table-sm th.gridjs-th {
  @apply text-xxs py-1 px-2 text-left font-semibold break-before-all border-b border-theme-rule-light md:text-xs md:py-2 md:px-3;
  min-width: auto !important;
  width: auto !important;
}
.ui-grid-table-sm td.gridjs-td {
  @apply text-xxs py-1 px-2 text-left font-normal break-before-all border-b border-theme-rule-light md:text-xs md:px-3;
  min-width: auto !important;
  width: auto !important;
}
.ui-grid-table-sm .ui-btn-hyperlink {
  @apply text-xxs p-0 text-left font-semibold w-auto m-0 break-before-all border-b border-theme-rule-light md:text-xs;
}
