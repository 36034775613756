.ui-breadcrumb li {
  @apply inline-block;
}
.ui-breadcrumb a {
  @apply relative text-ui-blue-dark pr-5 mr-4 hover:text-ui-green hover:bg-transparent;
}
.ui-breadcrumb a[aria-current="page"] {
  @apply font-bold no-underline bg-transparent cursor-default;
}
.ui-breadcrumb a::after {
  @apply absolute;
  content: '';
  width: 6px;
  height: 10px;
  top: 50%;
  margin-top: -5px;
  right: -3px;
  background: transparent url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 10'%3E%3Cpath fill='%23464646' d='M.293,9.707a1,1,0,0,1,0-1.414L3.586,5,.293,1.707A1,1,0,1,1,1.707.293l4,4a1,1,0,0,1,0,1.414l-4,4A1,1,0,0,1,.293,9.707Z' /%3E%3C/svg%3E") no-repeat;  
} 
.ui-breadcrumb a[aria-current="page"]::after {
  @apply hidden;
}



