@import "./css/theme-everlake.css";
/* @import "./css/theme-lumico.css"; */

.uiButton {
  background-color: var(--ui-blue) !important;
  font-family: "Salesforce Sans", sans-serif;
}
.uiButton:focus {
  outline: 1px solid #9e26ce;
}
@font-face {
  font-family: "Salesforce Sans";
  src: url("https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff")
      format("woff"),
    url("https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf")
      format("truetype");
}

@tailwind base;
@layer base {
  body {
    @apply text-theme-text-primary;
    background-color: var(--theme-bgcolor-portal);
  }
  h1 {
    @apply font-header mb-3;
    color: var(--theme-color-text-header);
    font-size: 2.5rem; /* 40px */
    line-height: 2.625rem; /* 42px */
  }
  h2 {
    @apply font-header mb-2;
    color: var(--theme-color-text-header);
    font-size: 2rem; /* 32px */
    line-height: 2.375rem; /* 38px */
  }
  hr,
  .ui-card hr,
  .ui-card-bar hr {
    @apply my-4 xl:my-6 border-t border-theme-rule-medium;
  }
  .ui-card hr,
  .ui-card-bar hr {
    @apply border-theme-rule-light;
  }
  hr.ui-mobile-collapse {
    @apply my-0 md:mx-4 xl:mx-6;
  }
  hr.ui-collapse {
    @apply m-0;
  }
  hr.ui-under-title {
    @apply my-4 xl:mb-6;
  }
  select,
  input,
  textarea {
    @apply relative text-sm p-2 px-4;
  }
  main a,
  .ui-dialog-body a {
    @apply underline hover:no-underline;
    color: var(--theme-color-hyperlink);
  }
  main a:hover,
  .ui-dialog-body a:hover {
    color: var(--theme-color-hyperlink-hover);
    background-color: var(--theme-bgcolor-hyperlink-hover);
  }
  main a:active,
  main a:visited,
  .ui-dialog-body a:active,
  .ui-dialog-body a:visited {
    color: var(--theme-bgcolor-hyperlink-visited);
  }
}

@tailwind components;
@layer components {
  @import "./css/ui.css";
  @import "./css/header.css";
  @import "./css/breadcrumbs.css";
  @import "./css/footer.css";
  @import "./css/badges.css";
  @import "./css/tabs.css";
  @import "./css/forms.css";
  @import "./css/table.css";
  @import "./css/messagecenter.css";
  @import "./css/dialog.css";
  @import "./css/cta.css";
}

/* @import "tailwindcss/utilities"; */
@tailwind utilities;
