.embla {
  position: relative;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  /* user-select: auto; */
  /* -webkit-touch-callout: none; */
  /* -khtml-user-select: auto; */
  /* -webkit-tap-highlight-color: transparent; */
}

.embla__slide {
  position: relative;
  min-width: 100%;
}