footer {
  @apply mt-4 xl:mt-6;
  color: var(--theme-color-footer-text);
  background-color: var(--theme-bgcolor-footer);
}
footer a {
  color: var(--theme-color-footer-link);
}
footer a:hover {
  @apply underline;
  color: var(--theme-color-footer-link-hover);
}
footer hr {
  border-color: var(--theme-color-footer-rule);
}