.ui-badge-icon {
  @apply inline-flex items-center p-1 pr-2 text-xxs leading-none font-semibold text-theme-text-primary border rounded;
}
.ui-badge-good {
  @apply border-ui-green bg-ui-green-light;
}
.ui-badge-good svg {
  @apply text-ui-green mr-2;
}
.ui-badge-risk {
  @apply border-ui-yellow bg-ui-yellow-light;
}
.ui-badge-risk svg {
  @apply text-ui-yellow mr-2;
}
.ui-badge-problem {
  @apply border-ui-red bg-ui-red-light;
}
.ui-badge-problem svg {
  @apply text-ui-red mr-2;
}

.ui-badge-text {
  @apply text-left leading-tight;
}

.ui-badge-icon-only {
  @apply inline-flex items-center p-0 bg-transparent border-transparent;
}
.ui-badge-icon-only svg {
  @apply mr-0;
}

/* Badge switches to icon only on mobile */
.ui-badge-switch-good,
.ui-badge-switch-risk,
.ui-badge-switch-problem {
  @apply inline-flex items-center p-0 border-0;
}
.ui-badge-switch-good svg {
    @apply text-ui-green;
}
.ui-badge-switch-risk svg {
  @apply text-ui-yellow;
}
.ui-badge-switch-problem svg {
   @apply text-ui-red;
}

@media screen(md) {
  .ui-badge-switch-good,
  .ui-badge-switch-risk,
  .ui-badge-switch-problem {
      @apply p-1 pr-2 text-xxs leading-none font-semibold text-theme-primary border rounded;
  }
  .ui-badge-switch-good svg,
  .ui-badge-switch-risk svg,
  .ui-badge-switch-problem svg {
      @apply mr-2;
  }  
  .ui-badge-switch-good{
    @apply border-ui-green bg-ui-green-light;
  }
  .ui-badge-switch-risk {
     @apply border-ui-yellow bg-ui-yellow-light;
  }
  .ui-badge-switch-problem {
     @apply border-ui-red bg-ui-red-light;
  }


}

/*  */
.ui-badge-bar {
  @apply absolute top-0 left-0 right-0 h-2 w-full z-20;
}
.ui-badge-bar-good {
  @apply bg-ui-green;
}
.ui-badge-bar-risk {
  @apply bg-ui-yellow;
}
.ui-badge-bar-problem {
  @apply bg-ui-red;
}

/* Pill with text */
.ui-badge-pill {
  @apply inline-flex px-2 py-1 text-2xxs font-semibold uppercase leading-none whitespace-nowrap rounded-full border bg-white;
}
.ui-badge-pill-good {
  @apply text-ui-green-dark border-ui-green;
}
.ui-badge-pill-risk {
  @apply text-ui-yellow-dark border-ui-yellow;
}
.ui-badge-pill-problem {
  @apply text-ui-red-dark border-ui-red;
}

/* dots next to text */
.ui-badge-dot {
  @apply inline-flex w-2 h-2 rounded-full mr-1;
}
.ui-badge-dot-good {
  @apply bg-ui-green;
}
.ui-badge-dot-risk {
  @apply bg-ui-yellow;
}
.ui-badge-dot-problem {
  @apply bg-ui-red;
}

/*  */
.ui-badge-bar-left-good:before,
.ui-badge-bar-left-risk:before,
.ui-badge-bar-left-problem:before {
  @apply absolute top-0 bottom-0 left-0 w-1 bg-ui-green;
  content: '';
  z-index: 5;
}
.ui-badge-bar-left-risk:before {
  @apply bg-ui-yellow;
}
.ui-badge-bar-left-problem:before {
  @apply bg-ui-red;
}



.ui-badge-icon-problem {
  @apply relative pl-4; 
}
.ui-input-error::after {
  @apply absolute rounded-full w-3 h-3;
  content: '';  
  top: 50%;
  margin-top: -6px;
  left: 0px;
  background: var(--ui-red)
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E %3Cg transform='translate(0 1.914)'%3E %3Cpath fill='%23FFFFFF' d='M8,7.1c-0.4,0-0.8-0.3-0.8-0.8V2.1c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.3C8.8,6.8,8.4,7.1,8,7.1z'/%3E %3Cpath fill='%23FFFFFF' d='M8,10.6c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3 c0-0.1,0.1-0.2,0.2-0.2C7.7,9,8.2,9,8.5,9.3c0.1,0.1,0.1,0.2,0.2,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3 c0,0.1-0.1,0.2-0.2,0.2C8.4,10.5,8.2,10.6,8,10.6z'/%3E %3C/g%3E %3C/svg%3E")
    no-repeat;
}

.ui-badge-inline-icon-good,
.ui-badge-inline-icon-risk,
.ui-badge-inline-icon-problem {
  @apply relative pl-5;
}
.ui-badge-inline-icon-good::before,
.ui-badge-inline-icon-risk::before,
.ui-badge-inline-icon-problem::before {
  @apply absolute rounded-full w-4 h-4 bg-no-repeat bg-ui-red;
  content: "";
  top: 50%;
  margin-top: -8px;
  left: 0px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E %3Cpath fill='%23FFFFFF' d='M8,9.1c-0.4,0-0.8-0.3-0.8-0.8V4.1c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.3C8.8,8.8,8.4,9.1,8,9.1z'/%3E %3Ccircle fill='%23FFFFFF' cx='8' cy='11.9' r='0.8'/%3E %3C/svg%3E");
}

.ui-badge-inline-icon-risk::before {
  @apply bg-ui-yellow;
}

.ui-badge-inline-icon-good::before {
  @apply bg-ui-green;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E %3Cpath fill='%23FFFFFF' d='M6.6,11.2C6.6,11.2,6.6,11.2,6.6,11.2c-0.2,0-0.4-0.1-0.5-0.2L4,8.7C3.7,8.4,3.7,7.9,4,7.7c0.3-0.3,0.8-0.3,1.1,0l1.6,1.7L11,5c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1l-5,5C7,11.1,6.8,11.2,6.6,11.2z'/%3E %3C/svg%3E");
}

@media screen(md) {
  .md\:ui-no-badge-icon-inline {
    @apply pl-0;
  }
  .md\:ui-no-badge-icon-inline:before {
    @apply hidden;
  }
}
