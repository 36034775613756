.ui-cta-actions a,
.ui-cta-actions button button {
  @apply mr-2 mt-2;
}
@media screen(sm) {
  .ui-cta-photo-mask {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0.8) 86%,
      rgba(255, 255, 255, 0) 99%
    );
  }
}

.ui-text-shadow-black {
  text-shadow: 0px 0px 2px #000000;
}

@media screen(md) {
  .md\:ui-text-shadow-black {
    text-shadow: 0px 0px 2px #000000;
  }
}

/* Future base HTML element stylings */
/*
.ui-rte-component ul {
    list-style: initial;
    margin: initial;
    padding: 0 0 0 40px;
} */
