
.ui-utilitybar {
   @apply  border-b;
   border-color: var(--theme-color-utility-border-bottom);
   background-color: var(--theme-bgcolor-utility-mobile); 
}
.ui-utilitybar {
   background-color: var(--theme-bgcolor-utility);
}
.ui-utilitybar .ui-btn-icon {
   color: var(--theme-color-utility-icons);
   background-color: var(--theme-bgcolor-utility-icons);
}
.ui-utilitybar .ui-btn-icon:hover {
   color: var(--theme-color-utility-icons-hover);
   background-color: var(--theme-bgcolor-utility-icons-hover);
}      

.ui-utilitybar .ui-disclosure-nav > li > a,    
.ui-main-menu .ui-disclosure-nav > li > a,
.ui-main-menu .ui-disclosure-nav button {
   @apply flex relative px-4 py-3.5 w-full font-semibold text-sm text-left border-b border-ui-gray-medium-light;        
}

.ui-main-menu .ui-disclosure-nav button[aria-expanded]::after {
   @apply absolute w-4 h-4 ease-in-out duration-700;      
   content: '';  
   width: 12px;
   height: 6px;
   top: 50%;    
   right: 10px;      
   background: transparent url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 6'%3E%3Cg transform='translate(1.061 1.061)'%3E%3Cpath fill='%23464646' d='M4.9,4.9c-0.2,0-0.4-0.1-0.6-0.2L0,0.3C-0.3,0-0.3-0.5,0-0.8s0.8-0.3,1.2,0L4.9,3l3.8-3.8c0.3-0.3,0.8-0.3,1.2,0s0.3,0.8,0,1.2L5.5,4.7C5.4,4.9,5.1,4.9,4.9,4.9z'/%3E%3C/g%3E%3C/svg%3E") no-repeat;
}

.ui-main-menu .ui-disclosure-nav button[aria-expanded="true"]::after {
      @apply rotate-180 ease-in-out duration-700;
   }

.ui-utilitybar .ui-disclosure-nav > li > a:hover, 
.ui-main-menu .ui-disclosure-nav > li > a:hover,
.ui-main-menu .ui-disclosure-nav button:hover,
.ui-main-menu .ui-disclosure-nav button.ui-current:hover {
   @apply underline;
   color: var(--theme-color-navbar-hover-text); 
   background-color: var(--theme-bgcolor-navbar-current-mobile);          
}

.ui-utilitybar .ui-disclosure-nav ul a,
.ui-main-menu .ui-disclosure-nav ul a {
   @apply relative flex text-xs py-2 pl-8 pr-4 text-theme-text-primary;
}

.ui-utilitybar .ui-disclosure-nav ul a:hover,
.ui-utilitybar .ui-disclosure-nav ul a:focus,
.ui-main-menu .ui-disclosure-nav ul a:hover,
.ui-main-menu .ui-disclosure-nav ul a:focus {
   @apply underline;      
   background-color: var(--theme-bgcolor-navbar-current-mobile);      
}


.ui-main-menu .ui-disclosure-nav button.ui-current,
.ui-main-menu .ui-disclosure-nav a.ui-current {
   @apply relative;
   color: var(--theme-color-navbar-current-mobile);
   background-color: var(--theme-bgcolor-navbar-current-mobile);
}

.ui-main-menu .ui-disclosure-nav button.ui-current::before,
.ui-main-menu .ui-disclosure-nav ul a.ui-current::before,
.ui-main-menu .ui-disclosure-nav a.ui-current::before{
   @apply absolute top-0 bottom-0 left-0;
   content: '';
   width: 7px;
   height: 100%;
   display: block;
   background-color: var(--theme-bgcolor-navbar-current);
}

.ui-main-menu .ui-disclosure-nav a.ui-current,
.ui-main-menu .ui-disclosure-nav a.ui-current:hover,
.ui-main-menu .ui-disclosure-nav a.ui-current:focus {      
   @apply cursor-default no-underline;      
   background-color: var(--theme-bgcolor-navbar-current-mobile);
}



.ui-main-menu .ui-disclosure-nav ul a.ui-current::after {
   @apply absolute;
   content: url("/public/assets/img/icon-checkmark-blue.svg");
   z-index: 10;
   right: 8px;
   top: 50%;
   margin-top: -9px;
   width: 18px;
   height: 18px;
   display: block;
}



@media screen(lg){
   
   .ui-navbar {
      color: var(--theme-color-navbar-text);
      background-color: var(--theme-bgcolor-navbar);     
   }


   .ui-utilitybar .ui-disclosure-nav > li > a,
   .ui-main-menu .ui-disclosure-nav > li > a,
   .ui-main-menu .ui-disclosure-nav button {
      @apply inline-block border border-transparent;
   }

   .ui-utilitybar .ui-disclosure-nav > li > a:hover,
   .ui-main-menu .ui-disclosure-nav > li > a:hover,
   .ui-main-menu .ui-disclosure-nav button:hover,
   .ui-main-menu .ui-disclosure-nav button.ui-current:hover {
      @apply underline border-ui-gray-medium-light;
      color: var(--theme-color-navbar-hover-text);
      background-color: var(--theme-bgcolor-navbar-hover);
   }

   .ui-main-menu .ui-disclosure-nav button[aria-expanded="true"],
   .ui-main-menu .ui-disclosure-nav button.ui-current[aria-expanded="true"] {
      @apply border border-ui-gray-medium-light;
      color: var(--theme-color-navbar-hover-text);
      background-color: var(--theme-bgcolor-navbar-hover);
   }

   .ui-main-menu .ui-disclosure-nav button[aria-expanded]::after,
   .ui-main-menu .ui-disclosure-nav button[aria-expanded="true"]::after,
   .ui-main-menu .ui-disclosure-nav button[aria-expanded="false"]::after {
      @apply hidden;
   }

   .ui-utilitybar .ui-disclosure-nav ul,
   .ui-main-menu .ui-disclosure-nav ul {
      @apply absolute text-sm py-2;
      display: block;
      list-style-type: none;
      margin: 0;
      min-width: 200px;
      left: 0px;
      z-index: 90;
      top: calc(100% + 18px);
   }
   .ui-utilitybar .ui-disclosure-nav ul {
      @apply border-t border-b border-r-0 border-l-0 border-ui-gray-medium-light;
      top: calc(100% + 6px);         
   }
   .ui-utilitybar .ui-disclosure-nav ul .ui-arrow,
   .ui-main-menu .ui-disclosure-nav ul .ui-arrow {
      @apply absolute;
      top: -12px;
      left: 50%;
      z-index: 80;
      margin-left: -7px;
      width: 0px;
      height: 0px;
      border-width: 0 7px 12px 7px;
      border-color: transparent;
      border-bottom-color: var(--ui-gray-medium-light);
      border-style: solid;
      opacity: 1;
   } 
   .ui-utilitybar .ui-disclosure-nav ul .ui-arrow { 
      margin-right: -10px; 
   }
   .ui-utilitybar .ui-disclosure-nav ul .ui-arrow::before,
   .ui-main-menu .ui-disclosure-nav ul .ui-arrow::before {
      content: '';
      position: absolute;
      top: -2px;
      left: 50%;
      z-index: 78;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-width: 0 8px 14px 8px;
      border-color: transparent transparent #333333 transparent;
      border-style: solid;            
      opacity: .03;
   } 
   
   .ui-utilitybar .ui-disclosure-nav ul .ui-arrow::after,
   .ui-main-menu .ui-disclosure-nav ul .ui-arrow::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 50%;
      z-index: 80;
      margin-left: -7px;
      width: 0px;
      height: 0px;
      border-width: 0 7px 12px 7px;
      border-color: transparent transparent #ffffff transparent;
      border-style: solid;
      opacity: 1;
   } 


   .ui-utilitybar .ui-disclosure-nav li,
   .ui-main-menu .ui-disclosure-nav li {
      @apply mx-0; 
   }

   .ui-utilitybar .ui-disclosure-nav > li,
   .ui-main-menu .ui-disclosure-nav > li {
      display: flex;
      position: relative;
   }
   .ui-utilitybar .ui-disclosure-nav ul a,
   .ui-main-menu .ui-disclosure-nav ul a {
      @apply block py-1 my-1 px-4 border border-transparent text-theme-text-primary;
   }

   .ui-utilitybar .ui-disclosure-nav ul a:hover,
   .ui-utilitybar .ui-disclosure-nav ul a:focus,
   .ui-main-menu .ui-disclosure-nav ul a:hover,
   .ui-main-menu .ui-disclosure-nav ul a:focus {
      @apply mx-2 px-2 rounded underline;      
      background-color: var(--ui-gray-light);
   }

   .ui-main-menu .ui-disclosure-nav button.ui-current,
   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current {
      color: var(--theme-color-navbar-current-text);
      background-color: var(--theme-bgcolor-navbar-current);
   }

   .ui-main-menu .ui-disclosure-nav button.ui-current::before,
   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current::before {
      @apply hidden;
   }


   .ui-utilitybar .ui-disclosure-nav ul a.ui-current,
   .ui-utilitybar .ui-disclosure-nav ul a.ui-current:hover,
   .ui-utilitybar .ui-disclosure-nav ul a.ui-current:focus,
   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current,
   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current:hover,
   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current:focus,      
   .ui-main-menu .ui-disclosure-nav ul a.ui-current,
   .ui-main-menu .ui-disclosure-nav ul a.ui-current:hover,
   .ui-main-menu .ui-disclosure-nav ul a.ui-current:focus {      
      @apply relative mx-0 px-4 cursor-default rounded-none no-underline;      
      color: var(--theme-color-navbar-current-text);
      background-color: var(--theme-bgcolor-navbar-current);
   }
   

   .ui-utilitybar .ui-disclosure-nav ul a.ui-current,
   .ui-utilitybar .ui-disclosure-nav ul a.ui-current:hover,
   .ui-utilitybar .ui-disclosure-nav ul a.ui-current:focus,   
   .ui-main-menu .ui-disclosure-nav ul a.ui-current,
   .ui-main-menu .ui-disclosure-nav ul a.ui-current:hover,
   .ui-main-menu .ui-disclosure-nav ul a.ui-current:focus {      
      @apply pr-12;      
   }


   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current,
   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current:hover,
   .ui-main-menu .ui-disclosure-nav .ui-main-link.ui-current:focus {
      @apply border-transparent;
   }

   .ui-utilitybar .ui-disclosure-nav ul a.ui-current::after,
   .ui-main-menu .ui-disclosure-nav ul a.ui-current::after {
      @apply absolute;
      content: url("/public/assets/img/icon-checkmark-white.svg");
      z-index: 10;
      right: 8px;
      top: 50%;
      margin-top: -9px;
      width: 18px;
      height: 18px;
      display: block;
   }


   .ui-main-menu .ui-disclosure-nav button,
   .ui-main-menu .ui-disclosure-nav .main-link {
      display: flex;
   }
   
   .ui-main-menu .ui-disclosure-nav .main-link {
      border-right-color: transparent;
   }



   .ui-main-menu .ui-disclosure-nav .main-link + button::after {
      margin-left: 0;
   }
   

   
}




/* header search CSS */


.ui-header-search-mobile {
  @apply absolute bg-theme-background;
  z-index: 100;
  left: 250px;  
  bottom: 0;
  right: 0;
  top: 0;
 
}
.ui-header-search.ui-close .ui-header-search-desktop {
  @apply hidden;  
}
.ui-header-search.ui-open .ui-header-search-desktop {
  @apply block;  
}


  .ui-header-search {
    @apply relative mx-auto h-0 overflow-hidden bg-theme-background;
    transition: 1s ease height;     
    box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  }
  .ui-header-search.ui-close {
    @apply h-0; 
    transition: .5s ease height; 
  }
  .ui-header-search.ui-open {     
    @apply overflow-hidden;     
    height: 120px;
    transition: .5s ease height; 
    }  

.ui-utilitybar .ui-current {
  @apply relative;
}

.ui-utilitybar .ui-current::after {
  @apply absolute;
  content: "";
  bottom: -6px;
  left: 2px;
  right: 2px;
  height: 2px;
  background-color: var(--theme-color-utility-icons);
}
.ui-utilitybar .ui-current:hover::after {
  
}