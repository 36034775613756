.tabs {
  @apply rounded overflow-hidden;
  /* width: 20em;
font-family: "lucida grande", sans-serif; */
}

[role="tablist"] {
  @apply rounded-t overflow-hidden
  overflow-x-auto;

  /* margin: 0 0 -0.1em; */
  /* overflow: visible; */
}

[role="tab"] {
  @apply relative font-semibold border-b-2 pb-1 border-transparent;
}

[role="tab"][aria-selected="true"] {
  @apply bg-white border-theme-action pointer-events-none;
}

[role="tab"]:hover,
[role="tab"]:focus,
[role="tab"]:active {
  @apply border-transparent text-theme-action-hover underline;
  outline: 0;
}

[role="tab"][aria-selected="true"]:hover,
[role="tab"][aria-selected="true"]:focus,
[role="tab"][aria-selected="true"]:active {
  @apply text-theme-text-primary border-theme-action pointer-events-none no-underline;
}

.ui-tabs-sm [role="tab"] {
  @apply relative text-xxs font-normal p-1 bg-transparent border-0 border-transparent sm:text-xs sm:p-2 md:text-sm
  hover:text-theme-action-hover;
}
.ui-tabs-sm [role="tab"][aria-selected="true"] {
  @apply relative font-semibold border-theme-action;
}
.ui-tabs-sm [role="tab"][aria-selected="true"]::after {
  @apply absolute w-auto bg-theme-action bottom-0 left-1 right-1 sm:left-2 sm:right-2;
  content: "";
  height: 2px;
}
.ui-tabs-sm[role="tab"][aria-selected="true"]:hover,
.ui-tabs-sm[role="tab"][aria-selected="true"]:focus,
.ui-tabs-sm[role="tab"][aria-selected="true"]:active {
  @apply pointer-events-none;
}
