@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ramaraja&display=swap');


@font-face {
  font-family: 'Rector';
  font-display: swap;
  src: url('/public/assets/fonts/rector-webfont.woff2') format('woff2'),
       url('/public/assets/fonts/rector-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


:root {

  /* UI Colors */
  --ui-blue-light: #E1EDF5;
  --ui-blue: #0076b9;                 /* WCAG small text complaint on card background (--theme-bgcolor-cards) or portal background (--theme-bgcolor-portal), changed from mocks original #007bc1 */
  --ui-blue-dark: #00466E;
  --ui-blue-shadow: rgba(0,123,193,.8); /* not using */

  --ui-gray-lightest: #F9FAFB; /* background color of form inputs  */
  --ui-gray-light: #d6d6d6; 
  --ui-gray-medium-light: #c6c6c6;
  --ui-gray-medium: #949494;          /* Lightest WCAG complaint gray for graphical elements on card background (--theme-bgcolor-cards) */
  --ui-gray-medium-dark: #757575;     /* Lightest WCAG complaint gray for small text on card background (--theme-bgcolor-cards) */
  --ui-gray-default: #707070;         /* Lightest WCAG complaint gray for small text on card background (--theme-bgcolor-cards) or portal background (--theme-bgcolor-portal) */  
  --ui-gray-dark: #464646;
  --ui-gray-black: #222222;

  --ui-green-light: #E9F4ED;
  --ui-green: #288049;          /* WCAG complaint for small text on white or portal background (--theme-bgcolor-portal) */
  --ui-green-dark: #095F28;     /* WCAG complaint for small text on white or portal background (--theme-bgcolor-portal) */

  --ui-red-light: #F9EAEA;
  --ui-red: #C53030;            /* WCAG complaint for small text on white or portal background (--theme-bgcolor-portal) */  
  --ui-red-dark: #742A2A;       /* WCAG complaint for small text on white or portal background (--theme-bgcolor-portal) */    

  --ui-yellow-light: #FAF2E9;
  --ui-yellow: #CC8129;         /* WCAG complaint for graphical elements on card background (--theme-bgcolor-cards) */  
  --ui-yellow-dark: #975A16;    /* WCAG complaint for small text on white or portal background (--theme-bgcolor-portal) */  





  /* Theme Brand Font */
  --theme-font-body: 'Open Sans', Arial, Helvetica, sans-serif;
  --theme-font-header: 'Rector', serif; 
  --theme-font-brand: 'Rector', serif; 

  /* Theme Brand Colors */
  --theme-color-primary: #19285f;     /* Everlake Midnight Blue */
  --theme-color-secondary: #3979B9;   /* Everlake Lake Blue */
  --theme-color-tertiary: #bcdfe8;    /* Everlake Sky Blue */

  /* Theme Font Colors */
  --theme-color-text-header: var(--ui-gray-dark);  /* Text color for H1 and H2 tags (must be WCAG complaint on portal background (--theme-bgcolor-portal)) */
  --theme-color-text-primary: var(--ui-gray-dark);  /* Default text color for body or portal (must be WCAG complaint on portal background (--theme-bgcolor-portal)) */
  --theme-color-text-secondary: #000000; /* Text color for data values in the cards (must be WCAG complaint on card background (--theme-bgcolor-cards)) */
  --theme-color-text-tertiary: var(--ui-gray-medium-dark);  /* Text color for small data labels in the cards, only on white background (must be WCAG complaint on card background (--theme-bgcolor-cards)) */
  --theme-color-text-quaternary: var(--ui-gray-default);  /* Text color for small text on either white or portal background (--theme-bgcolor-portal) (must be WCAG complaint on portal background (--theme-bgcolor-portal)) */
  --theme-color-text-quinary: var(--theme-color-primary);  /* Text color of card titles set to theme header font (must be WCAG complaint on card background (--theme-bgcolor-cards)) */


  --theme-color-action: var(--ui-blue);
  --theme-color-action-hover: var(--theme-color-primary);

  --theme-color-hyperlink: var(--ui-blue);                 /* Content Area Hyperlinks */
  --theme-color-hyperlink-hover: var(--ui-green);
  --theme-bgcolor-hyperlink-hover: var(--ui-gray-light);
  --theme-bgcolor-hyperlink-visited: var(--ui-blue-dark);


  --theme-bgcolor-portal: #f3f6f9;         /* background color of portal  */
  --theme-bgcolor-cards: #ffffff;         /* background color of cards  */
  --theme-color-rule-light: var(--ui-gray-light);
  --theme-color-rule-medium: var(--ui-gray-medium-light); /* rules outside card graphical element*/




  /* Theme Header's Utility Bar Colors */
  --theme-color-portal-title: var(--theme-color-primary);
  --theme-bgcolor-utility: #ffffff;                        /* background color behind logo and utility icons */
  --theme-color-utility-border-bottom: transparent;        /* For all white headers theme border between utilitybar and navbar  */   

  --theme-color-utility-icons: var(--ui-blue);
  --theme-bgcolor-utility-icons: transparent;
  --theme-color-utility-icons-hover: #ffffff;
  --theme-bgcolor-utility-icons-hover: var(--ui-blue);

  --theme-bgcolor-utility-mobile: var(--theme-color-primary);
  --theme-color-utility-icons-mobile: #ffffff;
  --theme-bgcolor-utility-icons-mobile: transparent;
  --theme-color-utility-icons-hover-mobile:var(--theme-color-primary);
  --theme-bgcolor-utility-icons-hover-mobile: #ffffff;   


  /* Theme Header's Main Menu Colors */
  /* may need to change the two SVGs (icon-checkmark-blue.svg and icon-checkmark-white.svg) to match any menu color changes */
  --theme-bgcolor-navbar: var(--theme-color-primary); 
  --theme-color-navbar-text: #C7D5E9;   
  --theme-bgcolor-navbar-hover: #ffffff;
  --theme-color-navbar-hover-text: var(--ui-gray-dark);   
  --theme-color-navbar-current-text: #ffffff;
  --theme-bgcolor-navbar-current: var(--ui-blue);   
  --theme-color-navbar-current-mobile: var(--ui-gray-dark);
  --theme-bgcolor-navbar-current-mobile: var(--theme-color-content-background); /* bgcolor also used for mobile menu hover background */


  /* footer theme colors */   
  --theme-bgcolor-footer: var(--theme-color-primary);
  --theme-color-footer-rule: rgba(255,255,255,0.2);
  --theme-color-footer-text: #ffffff;
  --theme-color-footer-link: #ffffff;
  --theme-color-footer-link-hover: #ffffff;


}



.ui-logo-header {
   height: 30px;
   width: 150px;
}

@media (min-width: 1024px) {
   .ui-logo-header {
      height: 32px;
      width: 160px;
   }
 }



/* Only for prototype, need to remove in PROD */
#everlakeLogoHeader,
#everlakeLogoFooter {
   display: inline-block;
}

#lumicoLogoHeader,
#lumicoLogoFooter {
   display: none;
}